var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{ref:"floatingIconBox",staticClass:"c-floating__ctn mshe-z-returnTop",class:{ 'float-icon__hide': _vm.scrollHide },style:({'top': `${_vm.iconTop}rem` }),attrs:{"id":"j-floating__ctn"}},[_c('div',{staticClass:"c-floating__main j-floating__main handle",style:({
      transform: `translateY(${_vm.iconPosition.move}px)`,
    }),attrs:{"tabindex":"0","role":"button"},on:{"touchstart":_vm.handleTouchStart,"touchmove":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleTouchMove.apply(null, arguments)},"touchend":_vm.handleTouchEnd}},[_c('div',{staticClass:"c-floating__main__inner"},[_c('swiper-container',{ref:"floatingSwiper",staticClass:"c-floating__main__inner__container",attrs:{"init":"false","destroy-on-disconnected":"false"}},_vm._l((_vm.items),function(item,index){return _c('swiper-slide',{key:index},[_c('BaseImg',{directives:[{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', {item, index, useBffApi: _vm.useBffApi})),expression:"getAnalysisData('2-22-1', {item, index, useBffApi})"},{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', {item, index, useBffApi: _vm.useBffApi})),expression:"getAnalysisData('2-22-2', {item, index, useBffApi})"}],staticClass:"base-img c-floating__main__inner__container__slide__img",attrs:{"placeholder":{
              width: item.image.width,
              height: item.image.height,
            },"ratio":item.image.ratio,"img-src":item?.image?.src,"first-screen":false,"imgDataExp":{
              useWebp: item.useWebp,
            },"fit":"contain","special-radius":_vm.GB_cssRight ? '0 4% 4% 0' : '4% 0 0 4%'}})],1)}),1),_vm._v(" "),_c('div',{staticClass:"c-floating__main__inner__close-icon",style:({'visibility': _vm.show ? '' : 'hidden' }),on:{"click":_vm.close}},[_c('div',{staticClass:"c-floating__main__inner__close-icon__relative"},[_c('div',{staticClass:"icon-background"}),_vm._v(" "),_c('Icon',{attrs:{"name":"sui_icon_close_10px","color":"#fff","size":"11"}})],1)])],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }