<template>
  <div
    v-if="showComp"
    class="information-flow j-da-event-box"
    data-tab-list="-"
    :data-poskey="newPosKeys"
  >
    <div
      ref="informationFlowContent"
      class="information-flow__content"
    >
      <div
        v-if="title && showFlowTab"
        class="information-flow__content-title"
        :style="titleStyle"
      >
        {{ title }}
      </div>
      <div
        v-infinite-load="{
          distance: 1800,
          handle: handleScroll
        }"
        class="information-flow__content-list"
        :class="{
          'information-flow__content-list-two': rowNum === 2
        }"
        :infinite-load-nodata="`${ isLastPage }`"
      >
        <InformationFlowContent
          ref="informationWaterFlow"
          :index="index"
          :context="context"
          :prop-data="propData"
          :scene-data="sceneData"
          :channel-id="channelId"
          :information-flow-language="informationFlowLanguage"
          :channel-name="channelName"
          :channel-index="curChannelIndex"
          :tsp-ids="tspIds"
          :item-config="itemConfig"
          :isJumpDetail="isJumpDetail"
          :new-pos-keys="newPosKeys"
          :waterItems="waterItems"
          :row-num="rowNum"
          :scene-id="sceneId"
          :isPaid="!!sheinClubStatus?.isPaid"
          :trendConfig="trendConfig"
          :entry-from-content="entryFromContent"
          :local-index="localIndex"
          :dynamic-insert-num="dynamicInsertNum"
          :survey-info="surveyInfo"
          @flowContentMounted="initData"
        />
        
        <!-- placeholder 占位 -->
        <template v-if="!isContentLoaded">
          <PlaceholderItem
            :num="20"
            :column="rowNum"
            :brand="brand"
            type="vertical"
          />
        </template>
      </div>
      <div
        v-show="showViewMoreText"
        class="view-more-line"
        :class="{ 'view-more__not-bg': true }"
      >
        <div
          v-tap="getViewMoreAnalysis('2-22-10')"
          v-expose="getViewMoreAnalysis('2-22-9')"
          class="view-more-text"
          tabindex="0"
          role="text"
          :aria-label="viewMoreText"
          @click="loadMore"
          @keydown.enter.prevent="loadMore"
        >
          <span>
            {{ viewMoreText }}
            <i
              class="suiiconfont sui_icon_more_down_16px"
            ></i> 
          </span>
        </div>
      </div>

      <LazyMount mount-prop-name="show">
        <s-loading
          :show="pageStatus.busy"
        />
      </LazyMount>
    </div>
  </div>
</template>

<script>
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { infiniteLoad } from 'public/src/pages/config_index/common/infinite-scroll-directive.js'
import schttp from 'public/src/services/schttp'
import { mapGetters } from 'vuex'
import { localStorageHelper, defaultCardConfig, defaultAtomFields } from './utils'
const { language, lang } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { dealProductsPretreatInfo } from '@/public/src/services/goodsItemInfo/goodsPretreatInfo'
import { daEventCenter } from 'public/src/services/eventCenter'
import { LazyMount } from '@shein/sui-mobile'
import lazyComponent from 'public/src/services/lazyComponent'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { INFORMATIONFLOW_POSKEY } from 'public/src/services/abt/config/home.js'
import { getFeedbackRecInSession, setClickAndRecommend, getClickAndRecommend, clearFeedbackRecInSession, getListExposeGoodsSesstion, clearListExposeGoodsSesstion, setListExposeGoodsSesstion } from 'public/src/pages/product_list_v2/js/utils'
import { getRecGoodsInfo } from 'public/src/pages/rec_product_list/js/useRecommendApi.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { getSurveyInfo } from 'public/src/pages/components/product/carrier_ui/survey-question/utils'
import { showSurveyComponent } from 'public/src/pages/components/product/carrier_ui/survey-question/utils'

import NetworkDetect from 'public/src/pages/common/network-detect/index.js'
const networkDetect = new NetworkDetect({ isOnce: true })
daEventCenter.addSubscriber({ modulecode: '2-22-9-1' })
const daEventExpose = daEventCenter.getExposeInstance()
let atomFields = {}
let promotionInfoSerInfo = null
let requestInstance = null
export default {
  name: 'InformationFlowCcc', // 首页信息流页面
  provide() {
    return {
      analysisData: {
        entryFrom: this.entryFromCard,
      }
    }
  },
  components: {
    PlaceholderItem,
    LazyMount,
    InformationFlowContent: lazyComponent({
      componentFactory: () => import(/* webpackChunkName: "informationFlowCccContent" */'./InformationFlowContent.vue'),
    }),
  },
  directives: { infiniteLoad },
  mixins: [mixins],
  props: {
    nowChannelIdKey: {
      type: [Number, String],
      default: ''
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0
    },
    context: {
      type: Object,
      default: () => ({})
    },
    propData: {
      type: Object,
      default: () => ({ props: { metaData: {} } })
    },
    sceneData: {
      type: Object,
      default: () => ({ pageFrom: 'home' })
    },
    brand: {
      type: String,
      default: 'shein'
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { titleColor = '#222222', viewMorePosition = 5, imgSrc } = this.propData?.content?.props?.style || {}
    
    return {
      showComp: true,
      titleColor,
      imgSrc,
      viewMorePosition,
      language,
      pageStatus: {
        requestType: 'init', //为了处理无限滚动 init初始化，nextPage下一页
        busy: false, // 正在ajax请求
        goodsLoadedOnPageList: true, // 当前刷的商品是否渲染完成
      },
      rowNum: 2,
      products: [],
      isLastPage: false, // 由于70场景推荐接口不返回total字段，所以只能通过判断当前请求返回的商品数是否小于limit来判断是否是最后一页
      jsonRuleId: null,
      ruleId: null,
      total: 100,
      pageNum: 1,
      limit: 20,
      firstLoad: true,
      isLeaving: false,
      abtInfoV3: null,
      newPosKeys: INFORMATIONFLOW_POSKEY.join(','),
      itemConfig: {
        commonParams: {
          pageName: 'info-flow'
        }
      },
      showFlowTab: false,
      isContentLoaded: false,
      isJumpDetail: false, //是否跳转到商详
      recommendPoskey: '', // 推荐请求的posKey
      sceneId: 205, // 推荐请求的场景id
      // 根据 abt 实验开关控制，如果 contextForSSR?.content?.cccxConfig 存在，则说明开启了实验
      cccxConfig: this.context?.content?.cccxConfig,
      informationFlowLanguage: {}, //趋势内容体多语言
      useGoodIdList: [], // 已经点后推的商品Id
      localIndex: -1,
      dynamicInsertNum: 0, // 针对信息流动态插坑个数
      promotionLanguage: {}, //促销多语言,
      remainProducts: [], // 因转场未能及时渲染的商品数据
      sheinClubStatus: {}, //会员信息
      useBffApi: {}, //中间层融合开关
      trendConfig: {}, // 趋势内容体额外的商卡配置
      isClick: false,
      cardConfigCompleted: false, // 商卡配置是否初始化完成
      surveyInfo: {}, // 问卷调查信息
      showSurveyComponent: false, // 展示问卷调查组件
      isNeedRecordClickComp: false, // 返回首页时，首页向下滑动
    }
  },
  computed: {
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    useCccxConfigToControlStyle() {
      return !!this.cccxConfig
    },
    title() {
      const title = this.language?.SHEIN_KEY_PWA_15555
      if (this.useCccxConfigToControlStyle) {
        return this.cccxConfig?.tabList?.[0]?.tabShowTitle || ''
      }

      return title
    },
    curChannelIndex() {
      return this.topTabList.findIndex(item => item.channelId == this.channelId)
    },
    channelName() {
      return this.topTabList[this.curChannelIndex]?.channelName
    },
    canLoadMore() { // 非加载中 && 当前商品数< max && 0 && 不是在最后一页
      return !this.pageStatus.busy && this.products.length < 1000 && !this.isLastPage
    },
    showViewMoreText() {
      return this.canLoadMore && this.products.length === this.viewMorePosition * this.limit && this.viewMorePosition != 0
    },
    loadingStatus() {
      return {
        nextPage: this.pageStatus.busy && this.isNextPage
      }
    },
    viewMoreText() {
      return this.language?.SHEIN_KEY_PWA_15698
    },
    titleStyle() {
      if (this.useCccxConfigToControlStyle) {
        return {
          color: this.cccxConfig?.overallConfig?.titleColor,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${this.cccxConfig?.overallConfig?.topImage?.src || 'https://img.ltwebstatic.com/images3_ccc/2024/07/31/a6/1722411552306dd0c1516672f248ea54a782bbce74.png'})`
        }
      }

      return {
        color: this.titleColor, backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat', backgroundImage: `url(${this.imgSrc})`
      }
    },
    cateIds() {
      const curChannelId = this.channelId
      const curTab = this.topTabList.find(tab => {
        return tab.channelId === curChannelId
      })
      return curTab?.realCateIds ?? ''
    },
    tspIds() {
      const curChannelId = this.channelId
      const curTab = this.topTabList.find((tab, index) => {
        return tab.channelId === curChannelId && index !== 0
      })
      return curTab?.recommendedScopeType ? curTab?.goodsPoolId : ''
    },
    // 商卡趋势标签埋点使用
    entryFromCard() {
      const pageName =  window?.getSaPageInfo?.page_name || 'page_home'
      const entryFromList = [`${pageName}`, `${this.curChannelIndex + 1}`,  `${this.channelName}`, 'trend_card']
      return entryFromList.join('`')
    },
    // 趋势内容体埋点使用
    entryFromContent() {
      const pageName =  window?.getSaPageInfo?.page_name || 'page_home'
      const entryFromList = [`${pageName}`, `${this.curChannelIndex + 1}`, `${this.channelName}`, 'auto_rcmd_info_flow']
      return entryFromList.join('`')
    },
    waterItems() {
      if (!this.showSurveyComponent) return this.products
      const waterItems = []
      this.products.forEach((goodsItem, index) => {
        if (+this.surveyInfo.showPosition === index + 1) {
          waterItems.push(this.surveyInfo)
        }
        waterItems.push(goodsItem)
      })
      return waterItems
    },
  },
  watch: {
    $route(to, from) {
      // hack code. 处理切换首页tab 因为keepalive导致多个信息流实例存在 $route触发N次问题
      if(this.channelId !== this.nowChannelIdKey) {
        return
      }
      if (to.path !== from.path) {
        this.isLeaving = true
      }
      if (to.name === 'config_index') {
        // 切换tab 清点后推缓存
        if(from.name === 'config_index' && to?.query?.activeChannelId !== from?.query?.activeChannelId) {
          this.initSessionStorage()
        }
        this.isLeaving = false
      }
      // 去到信息流落地页
      if (to.name === 'picked-info-list' && to.path !== from.path) {
        let { topGoodsId, carrierSubType } = to?.query
        // 内容体不进行点后推(全集内容体需要推)
        if(!carrierSubType || carrierSubType === '27') {
          // 当前点击
          setClickAndRecommend(topGoodsId)  
        }
      }
      // 离开当前页面(处理)
      if(to.path !== from.path && to.name !== 'config_index') {
        requestInstance?.abort()
        requestInstance = new SchttpAbortCon()
      }
      // 从信息流落地页返回
      if (to.name === 'config_index' && from.name === 'picked-info-list') {
        this.addRecommendProductHandle()
      }
    },
    isShow(newValue) {
      if (newValue) {
        daEventExpose.resetAll?.('informationFlow')
        this.remainProducts.length && this.setGoods(this.remainProducts, 1)
      }
    },
  },
  created() {
    this.initSessionStorage()
    appEventCenter.$on('surveyComponentExposed', this.onSurveyComponentExposed)
  },
  deactivated() {
    // 清除实时反馈相关的状态存储，防止影响
    clearFeedbackRecInSession()

    // 点击跳转记录，用于返回首页时重新定位信息流
    this.handleUnwatchClick()
  },
  activated() {
    daEventExpose.reset('flow-list') // 重置点后推的商品埋点
    daEventExpose.resetAll?.('informationFlow')
    this.remainProducts.length && this.setGoods(this.remainProducts, 1)
    // 点击跳转记录，用于返回首页时重新定位信息流
    this.handleWatchClick()
  },
  mounted() {
    // 点击跳转记录，用于返回首页时重新定位信息流
    this.handleWatchClick()
  },
  destroyed() {
    appEventCenter.$off('surveyComponentExposed', this.onSurveyComponentExposed)
    networkDetect.unWatch()
  },
  methods: {
    onSurveyComponentExposed() {
      this.showSurveyComponent = showSurveyComponent(this.surveyInfo, 2)
    },
    initSessionStorage() {
      if (typeof window !== 'undefined') {
        // 页面刷新，清除信息流落地页列表曝光的商品
        clearListExposeGoodsSesstion('INFORMATIONFLOW_LIST_PAGE')
        clearListExposeGoodsSesstion('INFORMATIONFLOW_LIST_ENTRY')
      }
    },
    // wiki: pageId=1394253488
    addRecommendProductHandle() {
      // abt 开关不打开就过滤
      let { flowInterestRecoEntry, RealtimeRecommendEntry } = this.abtInfoV3
      if(!flowInterestRecoEntry?.p?.InterestRecoEntry) {
        return
      }
      const { goodsId, addBagStatus } = getFeedbackRecInSession() || {}

      // eslint-disable-next-line no-unused-vars
      const { goodsId: ClickGoodsId, haveRecommendGood } = getClickAndRecommend() || {}
      // 不管哪种情况，只要当前点击的goodsId 为空，下面的逻辑就不需要走了
      if(!ClickGoodsId) {
        return 
      }

      // 如果落地页存在点击行为，则入参的goodsId是落地页的否则是入口点击处的
      let topGoodsId = goodsId || ClickGoodsId || ''
      
      // // 落地页存在点后推了，入口不需要了
      // if(haveRecommendGood) {
      //   return
      // }
      // 如果当前点击的商品已经点击过
      if(this.useGoodIdList.includes(goodsId) || this.useGoodIdList.includes(ClickGoodsId)) {
        return
      }
      // 给推荐的expose_goods_id入参，包括，当前页面已推商品（接口返回） + 点推商品 + 落地页曝光的商品（实际曝光）
      const generatingExposedGoodsIdFun = () => {
        let informationFlowList = getListExposeGoodsSesstion('INFORMATIONFLOW_LIST_PAGE')
        let haveGoodsId = this.products?.map(item => item.goods_id)?.filter(item=>item)?.join(',') // 点推的商品会插入products中，所以直接使用就好
        return informationFlowList ? `${informationFlowList},${haveGoodsId}` : `${haveGoodsId}`
      }
      let exposedGoodsId = generatingExposedGoodsIdFun() || this.products?.slice(0, 240)?.map(item => item.goods_id)?.filter(item=>item)?.join(',') || ''

      // 请求相关&&插入相关逻辑
      const insertRecommendGoodFun = async (goodsId, isClick, isAddbag) => {
        // 处理参数 && 请求接口
        let result = await getRecGoodsInfo({
          goodsId: goodsId,
          productLimit: +flowInterestRecoEntry?.p?.InterestRecoEntry,
          isClick: +isClick,
          isAddbag: +isAddbag,
          location: 'RealtimeRecommendEntry',
          jsonRuleId: JSON.stringify(RealtimeRecommendEntry?.p),
          exposedGoodsId: exposedGoodsId,
          atomFields,
          // 请求原子扩展字段
          atomScene: {
            pageKey: 'page_home_recommend',
            subPageKey: 'other_recommend_2pic',
            sceneKey: 'TWO_IN_A_ROW',
          },
          atomCccParams: {
            displayWindowAbtBranch: this.abtInfoV3?.detailgoodsCard
          },
          isPaid: this.sheinClubStatus?.isPaid,
          abtInfoV3: {
            listQuickShip: this.abtInfoV3?.listQuickShip,
            listquickKey: this.abtInfoV3?.listquickKey,
          }
        }, requestInstance)
        if(!result.length) {
          return 
        }
        let dealResult = dealProductsPretreatInfo({
          products: result, 
          itemConfig: this.itemConfig, 
          promotionInfoFromServer: promotionInfoSerInfo, 
          language: this.promotionLanguage,
        }) || []
        
        // 更改状态
        this.useGoodIdList.push(goodsId, ClickGoodsId)
        // 找到当前点击的商品index
        let localIndex = this.waterItems?.findIndex(item => item.goods_id == ClickGoodsId || (item.relatedColor || []).find(color => color.goods_id == ClickGoodsId))
        if(localIndex < 0) {
          return
        }
        // 落地页已经出点后推了，入口不需要了
        // setClickAndRecommend('', true)
        // 记录当前点击
        this.localIndex = localIndex

        // 存住点后推结果商品
        setListExposeGoodsSesstion('INFORMATIONFLOW_LIST_ENTRY', dealResult?.map(item=>item.goods_id)?.join(','))

        // 记录推荐商品（不需要点后推）
        this.useGoodIdList.push(...dealResult.map(item=>item.goods_id))
        // 只能用这个方式搞
        this.setGoods(dealResult, dealResult.length)
      }

      // 判断abt条件与当前用户交互是否匹配
      const abtMatchInteractiveFun = (goodsId, addBagStatus) => {
        // 不做限制，均展示点后推
        if(flowInterestRecoEntry?.p?.RecoEntryTime === 'all') {
          insertRecommendGoodFun(goodsId, addBagStatus !== undefined, addBagStatus)
          return
        }

        // 在落地页没有点击行为时(进落地页时会清掉实时反馈的存储，如果回来时不存在数据，说明没加车，没进商详)
        if(flowInterestRecoEntry?.p?.RecoEntryTime === 'noclick' && addBagStatus === undefined) {
          insertRecommendGoodFun(goodsId, 0, 0)
          return
        }

        // 商品加车成功时，展示点后推
        if(flowInterestRecoEntry?.p?.RecoEntryTime === 'addBag' && addBagStatus == 1) {
          insertRecommendGoodFun(goodsId, 1, 1)
          return 
        }
        
        // 商品没有加车成功时
        if(flowInterestRecoEntry?.p?.RecoEntryTime === 'noaddBag' && !addBagStatus) {
          insertRecommendGoodFun(goodsId, addBagStatus === undefined ? 0 : 1, 0)
          return
        }
      }

      abtMatchInteractiveFun(topGoodsId, addBagStatus)
    },
    async initData() {
      this.useBffApi = isSwitchBffApiVersion(['recomFlow_v1'])
      const abtInfo = await getUserAbtData()
      this.handleAbtResult(abtInfo)
      this.getProductList()
    },
    handleAbtResult(abtInfo) {
      const { 
        HomeRecommendNew, // 首频道（选品接口）
        HomeRecommendOtherNew, // 非首频道（选品接口）
        homePageStarReview,
        listflashSale,
        BigCard,
        infoflowTab,
        listquickship,
        listquickshipKey,
        listnewuserOnly,
        flowInterestRecoEntry,
        RealtimeRecommendEntry,
        listwithCoupon,
        detailgoodsCard,
        HomePageTerminal
      } = abtInfo || {}
      this.recommendPoskey = this.curChannelIndex === 0 ? 'HomeRecommendNew'  : 'HomeRecommendOtherNew'
      this.jsonRuleId = JSON.stringify(this.curChannelIndex === 0 ? HomeRecommendNew?.param  : HomeRecommendOtherNew?.param || {}) // param: { channelid1_ccc: 'A', channelid1_entry_rule_id: 'rec_ver:S70M2.15' }
      this.isJumpDetail = BigCard?.p?.BigCard === 'CardDetail'
      this.showFlowTab = !['Tab', 'hidetab'].includes(infoflowTab?.p?.flowTab) //实验值为Tab、hidetab时需隐藏Tab
      this.abtInfoV3 = {
        newProductCard: true,
        starReview: homePageStarReview?.param?.homePagestar, // 星级评论
        listflashSale: listflashSale?.p?.listflashSale == 'flashSale', // 闪购腰带
        listQuickShip: listquickship?.param?.listquickship, // QuickShip标签优化
        listquickKey: listquickshipKey?.param?.listquickKey, // QuickShip标签优化
        listnewuserOnly: listnewuserOnly?.p?.newuseronlyEligible === 'newuserEligible',
        flowInterestRecoEntry,
        RealtimeRecommendEntry, 
        detailgoodsCard: detailgoodsCard?.p?.detailGoods
      }
      this.trendConfig = {
        showNewStyleEstimated: listwithCoupon?.p?.listwithCoupon === 'withCoupon',  //到手价新样式
        showEstimatedPriceOnSale: listwithCoupon?.p?.listwithCoupon !== 'off' //命中off不展示到手价
      }
      this.dynamicInsertNum = +flowInterestRecoEntry?.p?.InterestRecoEntry || 0 // 针对信息流落地页动态插坑个数
      this.isNeedRecordClickComp =
        this.curChannelIndex === 0
          ? HomePageTerminal?.param?.HomePageReturnTopping_All === 'true'
          : HomePageTerminal?.param?.HomePageReturnTopping_Other === 'true'
    },
    exposeFilterProduct(removeList){
      daEventCenter.triggerNotice({
        daId: '2-22-9-1',
        bindData: removeList || [],
        extraData: {}
      })
    },
    pushMultipleWithLimit(array, elements, limit) {
      const filterElements = elements.map(item => {
        return { contentCarrierId: item.contentCarrierId, goods_id: item.goods_id, ext: item.ext }
      })
      array.push(...filterElements) // 将多个元素推送到数组末尾
      while (array.length > limit) {
        array.shift()// 从数组头部删除元素，直到数组长度不超过限制
      }
      localStorageHelper.setObject('informationFlowBrowsingHistory', array)
    },
    handleProductFilter(products, historyArr = [], carrierSubTypes = []){
      let pass = []
      let remove = []
      const filterFn = (_, isByGoodsId)=> {
        // 在当前组件里面数据里面或者历史记录100条能找到的话，就过滤掉
        if(isByGoodsId) { // 全集根据goods_id去重
          return this.products.some(product => product.goods_id == _.goods_id) || historyArr.some( his => his.goods_id == _.goods_id)
        }  // 非全集根据contentCarrierId去重
        return this.products.some(product => product.contentCarrierId == _.contentCarrierId) && historyArr.some( his => his.contentCarrierId == his.contentCarrierId)
      }
      for(let item of products) {
        if (item?.ext?.carrierSubType == '27') { // 全集根据goods_id去重
          filterFn(item, true) ? remove.push(item) : pass.push(item)
        } else if (carrierSubTypes.includes(+item?.ext?.carrierSubType)) { // 产品指定的类型，不参与去重、不参与浏览记录
          pass.push(item)
        } else {
          filterFn(item, false) ? remove.push(item) : pass.push(item)
        }
      }
      // 保留的和去掉的
      return { filterProducts: pass, removeProducts: remove }
    },
    // 商品曝光去重逻辑处理
    dealFilterProduct(products){
      // 如果返回的内容体类型是以下这几种的话，不参与去重、不参与浏览记录
      const carrierSubTypes = [20, 21, 22, 23, 24, 25, 36, 37, 38, 39, 40, 41, 42, 43, 44, 48, 49, 58, 60]
      // 记录的历史浏览100条
      const historyArr = localStorageHelper.getObject('informationFlowBrowsingHistory') || []
      // 过滤以后还剩下的需要给前端展示的商品,  由于重复，被移除掉的商品
      const { filterProducts, removeProducts } = this.handleProductFilter(products, historyArr, carrierSubTypes)
      // 将要浏览的商品保存在历史记录中
      this.pushMultipleWithLimit(historyArr, filterProducts.filter(_ => !carrierSubTypes.includes(+_.ext?.carrierSubType)), 100)
      // 曝光因为重复而被移除的商品
      this.exposeFilterProduct(removeProducts)
      return filterProducts
    },
    async getInformationData(useBffApi) {
      // 中间层融合后、只需要首次请求获取配置项数据
      if (this.pageNum !== 1 && useBffApi) {
        return {}
      }
      let { info = {} } =  await schttp({
        url: '/api/ccc/informationFlow/get',
        method: 'POST',
        data: {
          newPreHandle: true,
          abtInfoV3: this.abtInfoV3 || {},
          curChannelIndex: this.curChannelIndex,
          limit: this.limit,
          scene_id: this.sceneId,
          pageNum: this.pageNum,
          jsonRuleId: this.jsonRuleId,
          location: this.recommendPoskey,
          rule_id: this.ruleId,
          cate_id: this.tspIds ? '' : this.cateIds,
          tsp_id: this.tspIds,
          channel_id: this.channelId,
          useBffApi,
          // 请求原子扩展字段
          atomScene: {
            pageKey: 'page_home_recommend',
            subPageKey: 'other_recommend_2pic',
            sceneKey: 'TWO_IN_A_ROW',
          },
        }
      })
      return info
    },
    async getBffInformationData() {
      let { info = {} } =  await schttp({
        url: '/product/recommend/information_flow',
        method: 'GET',
        useBffApi: true,
        params: {
          curChannelIndex: this.curChannelIndex,
          channel_id: this.channelId,
          goodsPoolId: this.tspIds,
          realCateIds: this.tspIds ? '' : this.cateIds,
          limit: this.limit,
          page: this.pageNum,
          position: this.curChannelIndex + 1,
          recommendedScopeType: this.tspIds ? '1' : '0',
        }
      })
      return info
    },
    // 问卷调查接口
    async getSurveyInfo() {
      if (this.pageNum !== 1) {
        return {}
      }
      const params = {
        entrance_type: 'INFO_FLOW',
        page_type: 'homePageTab',
        cate_type: '0',
      }
      try {
        let { info = {} } =  await getSurveyInfo(params)
        return info
      } catch (error) {
        console.error('getSurveyInfo error:', error)
        return {}
      }
    },
    // 中间层接口数据adapter
    bffTransformData(products, isPaid, suggestedSaleType) {
      // 目前Web前端组件只支持展示新品、折扣、趋势、时尚店铺、运营位、全集，其他类型无法展示需过滤掉防止在瀑布流中占位，影响埋点坑位上报
      products = products.filter(
        _item => [12, 13, 27, 62, 63].includes(Number(_item?.carrierSubType)) || _item?.carrierType == '19'
      )
      return products.map(item => {
        let { specialList, productList, ...rest } = item
        // 商卡字段转换处理
        productList = productList?.map(v => {
          return goodsTransformation(v, {
            language: this.context?.language?.itemLanguage || {},
            lang: lang ?? 'us',
            isPaid,
            suggestedSaleType,
          })
        }) || []
        // 运营位转换处理
        if (item.carrierType === '19') {
          item['19'] = specialList
        } else if (['12', '13'].includes(item.carrierSubType)) { // 折扣、新品内容体转换处理
          item[item.carrierSubType] = productList
          item.cmp = { ...rest }
        } else if (item.carrierSubType === '62'){ // 趋势内容体转换处理
          const languageText = item.homeTrends?.growthLabel?.growthText
          const rateLocation = languageText.startsWith('{0}') ? 'before' : languageText.endsWith('{0}') ? 'after' : ''
          const growthText = rateLocation ? languageText.replace('{0}', '').trim() : languageText
          item = {
            ...productList[0],
            trend_info: {
              ...item.homeTrends,
              growthLabel: {
                ...item.homeTrends?.growthLabel,
                growthType: item.homeTrends?.growthLabel?.appMarkInfo,
                growthText,
                languageText,
              },
              trendId: item.homeTrends?.trendsId,
              trendTitle: item.homeTrends?.trendsName
            }
          }  
        } else {
          // 全集、时尚店铺内容体转换处理
          item = {
            ...productList[0]
          }
        }
        // 内容体标识等信息
        item.ext = { 
          ...rest,
          sc_url_id: item.scUrlId, 
        }
        item = { 
          ...rest, 
          ...item,
          rec_mark: item.recMark, // 埋点字段兼容
        }
        return item
      })
    },
    async getProductList() {
      if (!this.pageStatus.goodsLoadedOnPageList) {
        return
      }
      try {
        // 组件还没显示时，不请求接口
        if (!this.isShow) return
        const isUseBffApi = this.useBffApi['recomFlow_v1'] && false
        const requestQueen = [this.getInformationData(isUseBffApi), this.getSurveyInfo()]
        if (isUseBffApi) {
          requestQueen.push(this.getBffInformationData())
        }
        let  [{ products = [], cardConfig, informationFlowLanguage, promotionInfoSer, num = 0, fields, sheinClubStatus }, surveyInfo, result] = await Promise.all(requestQueen)
        if (this.pageNum === 1) {
          const goodsNameLine = result?.listStyle?.twoColumnStyle?.goodsNameLineLimit
          cardConfig ? Object.assign(this.itemConfig, cardConfig) : Object.assign(this.itemConfig, defaultCardConfig)
          if (goodsNameLine) {
            Object.assign(this.itemConfig, {
              showTitle: !!Number(goodsNameLine),
              showTitleTwoLine: Number(goodsNameLine) === 2,
            })
          }
          fields ? Object.assign(atomFields, fields) : Object.assign(atomFields, defaultAtomFields)
          promotionInfoSerInfo = promotionInfoSer
          this.informationFlowLanguage = informationFlowLanguage || {}
          this.sheinClubStatus = sheinClubStatus || {}
          this.surveyInfo = surveyInfo || {}
          this.showSurveyComponent = showSurveyComponent(this.surveyInfo, 2)
        }

        // 加车按钮是否显示
        if (!this.cardConfigCompleted && isUseBffApi && result?.informationFlow?.length) {
          for (let item of result.informationFlow) {
            if (item?.productList?.[0]?.productMaterial?.showAddButtonLabel) {
              this.itemConfig.showAddBagBtn = item?.productList?.[0]?.productMaterial?.showAddButtonLabel == '1'
              this.cardConfigCompleted = true
              break
            }
          }
        }

        // web之前没有容错逻辑，中间层接口有容错逻辑，所以先屏蔽下容错的逻辑
        if (isUseBffApi && +result?.faultTolerant !== 0) {
          result.num = 0
          result.informationFlow = []
        }
        isUseBffApi && (num = result?.num || 0)
        const informationFlowData = isUseBffApi ? this.bffTransformData(result?.informationFlow || [], !!this.sheinClubStatus?.isPaid, result?.suggestedSaleType) : products
        // 过滤掉浏览的前100条商品
        const filterProducts = this.dealFilterProduct(informationFlowData)
        let currentPageProducts = isUseBffApi ? filterProducts : dealProductsPretreatInfo({
          products: filterProducts, 
          itemConfig: this.itemConfig, 
          promotionInfoFromServer: promotionInfoSer, 
          language: this.informationFlowLanguage,
        }) || []
        // 新的预处理需要接受返回值，无assign混入
        
        this.firstLoad = false
        this.isLastPage = Number(num) < 10 // 产品要求，推荐接口返回10条，表示最后一页了

        // 为保证低端机性能, 推荐部分的商品项使用分块渲染
        this.pageStatus.goodsLoadedOnPageList = false
        this.isContentLoaded = true
        
        // 如果第一页推荐小于十条，不展示这个组件
        if (this.pageNum === 1 && Number(num) < 10) this.showComp = false 
        // 如果商品被全部过滤，不展示这个组件
        if (this.isLastPage && this.products.length == 0 && currentPageProducts.length == 0) this.showComp = false
        if (!this.showComp) {
          // 组件被隐藏掉后，后面逻辑无需执行，节省性能
          return
        }

        // 问卷调查返回的插坑位置超出了当前页面能加载的所有商品的数量，则放在最后一个
        if (this.isLastPage && this.surveyInfo.surveyId && this.products.length + currentPageProducts.length < +this.surveyInfo.showPosition) {
          currentPageProducts = currentPageProducts.push(this.surveyInfo)
        }
        // 如果加载过程中切换tab或者跳到其他页面时,将请求的数据缓存起来
        if (!this.isShow || this.isLeaving) {
          this.remainProducts = currentPageProducts
          return
        }
        this.setGoods(currentPageProducts, 1)

      } catch (e) {
        console.error('首页信息流接口执行报错', e)
        this.pageNum === 1 && (this.showComp = false )
        this.pageStatus.goodsLoadedOnPageList = true
        if (e instanceof XMLHttpRequest) {
          this.pageStatus.busy = true
          // 断网重连
          const handleReconnect = () => {
            this.getProductList()
          }
          networkDetect.addHandler(handleReconnect)
          networkDetect.watch()
        } else {
          this.pageStatus.busy = false
        }
      }
    },
    setGoods(goods, chunkSize) {
      // 如果加载过程中切换tab或者跳到其他页面时,将未渲染数据缓存起来，并跳出RAF递归，避免影响转场后的性能
      if ((!this.isShow || this.isLeaving) && goods.length) {
        this.remainProducts = goods
        return
      }
      // 一整页数据已加载完成, 并且 dom 也渲染完成, 瀑布流也塞好了商品
      if (goods.length === 0) {
        this.$nextTick(() => {
          this.pageNum++
          this.pageStatus.busy = false
          this.pageStatus.goodsLoadedOnPageList = true
          this.remainProducts = []
        })

        return
      }

      requestAnimationFrame(() => {
        const goodsChunk = goods.slice(0, chunkSize)
        this.products = [...this.products, ...goodsChunk]
        this.setGoods(goods.slice(chunkSize), chunkSize)
      })
    },
    handleScroll() {
      // 加载过程中和有展示view more情况下，不能调用loadMore()
      if (this.canLoadMore && !this.showViewMoreText && !this.firstLoad) {
        this.loadMore()
      }
    },
    getViewMoreAnalysis(id, code) {
      const data = {
        poskey: this.newPosKeys,
        scene_id: this.sceneId,
        tab_list: '-`-`-',
        page_index: this.pageNum
      }
      
      return {
        id,
        code,
        prefix: this.sceneData.pageFrom,
        data
      }
    },
    loadMore() {
      // 取消点后推动画（放点后推完成会导致列表因为重复渲染闪屏）
      // this.localIndex = -1
      if (this.loadingStatus.nextPage) return
      this.pageStatus.requestType = 'nextPage'
      this.pageStatus.busy = true
      this.getProductList()
      window.scrollBy(0, -1)
    },
    /**
     * 点击跳转记录，用于返回首页时重新定位信息流
     */
    handleWatchClick() {
      try {
        this.$refs.informationWaterFlow?.$el?.addEventListener('click', this.handleClickContainer, true)
        document.body.addEventListener('click', this.hanldeClickWindow, true)
      } catch(e) {
        console.error(e)
      }
    },
    handleUnwatchClick() {
      try {
        this.$refs.informationWaterFlow?.$el?.removeEventListener('click', this.handleClickContainer, true)
        document.body.removeEventListener('click', this.hanldeClickWindow, true)
        if (this.isClick && this.isNeedRecordClickComp) {
          sessionStorage.setItem(
            'information-flow-touched-class',
            this.$refs.informationFlowContent?.className,
          )
        }
      } catch(e) {
        console.error(e)
      }
    },

    handleClickContainer() {
      this.isClick = true
    },
    hanldeClickWindow() {
      // 防止点击外部元素
      this.isClick = false
    },
  },
}
</script>
<style lang="less">
.information-flow {
   // 给商卡
  --product-card-z-index: 1;
  position: relative;
  .clearfix();
  background-color: #F6F6F6;
  
  &.noTab {
    padding-top: .2667rem;
  }
  
  &__tab {
    border-bottom: none;
    z-index: @zindex-header;
    transition: top ease 0.3s;
    
    .S-tab__line {
      display: none;
    }
    
    .S-tab-item__inner {
      max-width: 200px !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  
  &__content-title {
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    color: #222;
    line-height: 2rem;
  }
  
  &__content-list {
    padding: 0 .1333rem;
  }
  
  &__content-list-two {
    
    .trend-one {
      margin-bottom: 0.16rem;
    }
    .product-item-ccc {
      border-radius: 0.1067rem;
      border: none;
      overflow: hidden;
      .camel-case-price__number-before-dot {
        font-size: .45333rem;
      }
    }
  }
}

.view-more-line {
  height: 0;
  
  .view-more-text {
    height: 1.07rem;
    line-height: 1.07rem;
    text-align: center;
    font-weight: bold;
    .font-dpr(24px);
    
    > span {
      vertical-align: middle;
    }
    
    i {
      vertical-align: middle;
      font-weight: normal;
    }
  }
}

.view-more__not-bg {
  .view-more-text {
    color: #666;
    background-color: #fff;
    box-shadow: 0 -18px 15px 0 #fff /*rtl:ignore*/;
  }
}

.view-more__has-bg {
  height: 0;
  
  .view-more-text {
    color: #fff;
  }
}
</style>
